<template>
	<PageTemplate>
		<Grid>
			<GridContainer size="12">
				<Stack border="b">
					<Section>
						<Heading size="1" uppercase>Portfolio</Heading>
					</Section>
				</Stack>
			</GridContainer>
		</Grid>
		<Grid>
			<GridContainer size="12 6@md">
				<Stack border="b n@md">
					<Section>
						<Laptop image="theme-ar/edison1.jpg" />
					</Section>
				</Stack>
			</GridContainer>
			<GridContainer size="12 6@md">
				<Stack direction="column" align="left" justify="spaceBetween" width="100%" height="100%" border="n l@md">
					<Stack width="100%"></Stack>
					<Section>
						<Heading size="2" margin="none">Edison</Heading>
						<Heading size="6" margin="none" uppercase>Corporate Website Design</Heading>
					</Section>
					<Stack width="100%" border="n b@md"></Stack>
					<Section>
						<Heading size="4">An informative site for partners and collaborators.</Heading>
						<Paragraph margin="none">The idea was to bring an ergonomics serving design, conveyed by a spacious page layout, and light micro-interactions to give a dynamic and different user-experience.</Paragraph>
					</Section>
					<Stack width="100%" border="n b@md"></Stack>
					<Section>
						<Button url="/portfolio/edison" label="VIEW SITE" color="tertiary" />
					</Section>
					<Stack width="100%"></Stack>
				</Stack>
			</GridContainer>
		</Grid>
		<Grid>
			<GridContainer size="12 4@md">
				<Stack border="tb">
					<Section size="large">
						<Link url="/portfolio/havelock">
							<Stack direction="column" width="100%" space="1">
								<Images width="100%" align="top" image="theme-ar/havelock1.jpg" border />
								<Heading size="6" margin="none" uppercase>Havelock</Heading>
								<Paragraph align="center" micro>Portfolio Website Design</Paragraph>
							</Stack>
						</Link>
					</Section>
				</Stack>
			</GridContainer>
			<GridContainer size="12 4@md">
				<Stack border="n tb@md">
					<Section size="large">
						<Link url="/portfolio/dallas">
							<Stack direction="column" width="100%" space="1">
								<Images width="100%" align="top" image="theme-ar/dallas3.jpg" border />
								<Heading size="6" margin="none" uppercase>Dallas</Heading>
								<Paragraph align="center" micro>Creative Website Design</Paragraph>
							</Stack>
						</Link>
					</Section>
				</Stack>
			</GridContainer>
			<GridContainer size="12 4@md">
				<Stack border="tb">
					<Section size="large">
						<Link url="/portfolio/windermere">
							<Stack direction="column" width="100%" space="1">
								<Images width="100%" align="top" image="theme-ar/windermere3.jpg" border />
								<Heading size="6" margin="none" uppercase>Windermere</Heading>
								<Paragraph align="center" micro>Corporate Website Design</Paragraph>
							</Stack>
						</Link>
					</Section>
				</Stack>
			</GridContainer>
		</Grid>
		<Grid>
			<GridContainer size="12 6@md">
				<Stack direction="column" align="left" justify="spaceBetween" width="100%" height="100%">
					<Stack width="100%"></Stack>
					<Section>
						<Heading size="2" margin="none">Sedona</Heading>
						<Heading size="6" margin="none" uppercase>Creative Website Design</Heading>
					</Section>
					<Stack width="100%" border="n b@md"></Stack>
					<Section>
						<Heading size="4">Feel the energy of the place.</Heading>
						<Paragraph margin="none">A graphic universe based on a soothing color palette, reminiscent of warm dessert and cool blue water tones, and a range of photographs showcasing the same colorful characteristics.</Paragraph>
					</Section>
					<Stack width="100%" border="n b@md"></Stack>
					<Section>
						<Button url="/portfolio/sedona" label="VIEW SITE" color="tertiary" />
					</Section>
					<Stack width="100%"></Stack>
				</Stack>
			</GridContainer>
			<GridContainer size="12 6@md">
				<Stack border="t l@md">
					<Section>
						<Laptop image="theme-ar/sedona1.jpg" />
					</Section>
				</Stack>
			</GridContainer>
		</Grid>

	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/annarobbins/template/Page'; 
export default {
	title: 'Anna Robbins',
  components: {
		PageTemplate
  },
	props: {
		theme: String
	},
  mounted() {
    let bodyElement = document.documentElement;
    localStorage.setItem("theme", this.theme);
    bodyElement.setAttribute('theme', this.theme);
  }
}

</script>

<style lang="scss">
</style>
